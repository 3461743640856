import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Container, Col } from "react-bootstrap"

const Privacy = () => (
  <Layout>
    <SEO
      title="The Leasing Intelligence Platform | SpaceIt"
      keywords={[
        `broker technology`,
        `commercial real estate`,
        `residential real estate`,
        `commercial real estate technology`,
        `residential real estate technology`,
        `real estate`,
        `spaceit`,
      ]}
    />

    <Container fluid className="px-0 main">
      <Container className="privacy py-5">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 className="f-24 fw-600">Privacy Policy</h2>
            <p>Last Modified: May 16, 2022</p>
            <p>
              Here at SpaceIt LLC ("SpaceIt" or "we" or "us" or "our"), we
              appreciate your use of, and contributions to, our website, our
              mobile and desktop applications, our other properties and/or our
              related services (collectively known as the "Services," or, each
              individually, a "Service"). SpaceIt respects your privacy and is
              committed to protecting your personal information through our
              compliance with this policy.
            </p>
            <p>
              Please read this policy carefully to understand our policies and
              practices regarding your personal information and how we will
              treat it. By accessing or using the Services, you agree to this
              privacy policy. If you do not agree with our policies and
              practices, please do not use the Services. This policy may change
              from time to time. Your continued use of the Services after we
              make changes is deemed to be acceptance of those changes, so
              please check the policy periodically for updates.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">
              Information We Collect About You and How We Collect It
            </h4>
            <p>
              We may collect several types of information from and about users
              of our Services, including:
            </p>
            <ul>
              <li className="pb-3">
                information by which you may personally be identified, such as
                your name, address, phone number, email address, credit card
                number, and any other identifier by which you may be contacted
                online or offline;
              </li>
              <li className="pb-3">
                age, gender, or other protected classifications;
              </li>
              <li className="pb-3">
                commercial information, such as purchase and use of a
                subscription to SpaceIt's software;
              </li>
              <li className="pb-3">
                geo-location data, such as the location of your device or
                computer;
              </li>
              <li className="pb-3">
                education and professional information you provide;
              </li>
              <li className="pb-3">
                inference data, such as information about your software usage
                preferences; and
              </li>
              <li className="pb-3">
                information about your internet connection, your IP address, and
                the equipment you use to access our Services and usage details.
              </li>
            </ul>
            <p>
              <strong>We may collect this information:</strong>
            </p>
            <ul>
              <li className="pb-3">
                directly from you when you provide it to us; or
              </li>
              <li className="pb-3">
                automatically as you navigate through the site. Information
                collected automatically may include usage details, IP addresses
                and information collected through cookies, web beacons and other
                tracking technologies.{" "}
              </li>
            </ul>
            <p>
              <strong>Information Provided to Us.</strong> The information we
              collect on or through our Services may include:
            </p>
            <ul>
              <li className="pb-3">
                Information that you provide by filling in forms on our
                Services. This includes information provided at the time of
                registering to use our Services or subscribing to our Services.
              </li>
              <li className="pb-3">
                Records and copies of your correspondence (including e-mail
                addresses), if you contact us.
              </li>
              <li className="pb-3">
                Details of transactions you carry out through our Services,
                which may require SpaceIt to collect your credit/debit card
                information. This information is secured using industry-standard
                encryption technology. SpaceIt will use this information solely
                to complete your transaction and will not share this information
                with outside parties, except to the extent necessary to complete
                the transaction or comply with applicable law.
              </li>
              <li className="pb-3">Your search queries on our Services.</li>
            </ul>
            <p>
              <strong>
                Information We May Collect Through Automatic Data Collection
                Technologies.{" "}
              </strong>{" "}
              As you navigate through and interact with our Services, we may use
              automatic data collection technologies to collect certain
              information about your equipment, browsing actions and patterns.
              The technologies we use for this automatic data collection may
              include:
            </p>
            <ul>
              <li className="pb-3">
                <strong>Cookies.</strong> Cookies are bits of electronic
                information that can be transferred to your computer or other
                electronic device to uniquely identify your browser. When you
                use the Services, we may place one or more cookies on your
                computer or other electronic device. We may use cookies to
                connect your activity on the Services with other information we
                store about you in your account profile or your prior
                interactions on the Services to, for example, store your
                preferences. The use of cookies helps us improve the quality of
                the Services to you, by identifying information which is most
                interesting to you, tracking trends, measuring the effectiveness
                of advertising, or storing information you may want to retrieve
                on a regular basis. At any time, you may adjust settings on your
                browser to refuse cookies according to the instructions related
                to your browser.
              </li>
              <li className="pb-3">
                <strong>Web Beacons. </strong> Pages of our website may contain
                small electronic files known as web beacons (also referred to as
                clear gifs, pixel tags and single-pixel gifs) that permit
                SpaceIt to collect certain information, such as counting the
                number of users who have visited those pages or to collect other
                related website statistics (for example, recording the
                popularity of certain website content and verifying system and
                server integrity).
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">
              Third Party Use of Cookies and Web Beacons
            </h4>
            <p>
              Some of the Services may be served by third parties, including
              advertisers, ad networks and servers, content providers and
              application providers. These third parties may use cookies alone
              or in conjunction with web beacons or other tracking technologies
              to collect information about you when you use our Services. The
              information they collect may be associated with your personal
              information or they may collect information, including personal
              information, about your online activities over time and across
              different websites and other online services. They may use this
              information to provide you with interest-based (behavioral)
              advertising or other targeted content, which is discussed further
              in the section below under the heading, "Behavioral Advertising."
            </p>
            <p>
              We do not control these third parties tracking technologies or how
              they may be used. If you have any questions about an advertisement
              or other targeted content, you should contact the responsible
              provider directly. For information about how you can opt out of
              receiving targeted advertising from many providers, please refer
              to the section below entitled, "Opting Out of Collection of
              Information by Third Parties."
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">Usage and Disclosure</h4>
            <p>
              In general, SpaceIt will use the information that we collect about
              you or that you provide to us to communicate with you and operate
              our Services. SpaceIt may also use this information to respond to
              your requests and may present you with advertising that we believe
              will be of interest or useful to you.
            </p>
            <p>
              Your privacy is important and we are committed to protecting
              information that identifies you personally, where you have chosen
              to keep it private. If you provide us personal information about
              others, or if others give us your information, we will only use
              that information for the specific reason for which it was provided
              to us. We will only share personal information that you provide
              outside the public areas of the Services in the following
              circumstances:
            </p>
            <ul>
              <li className="pb-3">
                When you consent or direct SpaceIt to share your personal
                information;
              </li>
              <li className="pb-3">
                To contractors, service providers and other third parties we use
                to support our Services and business, and subject to the terms
                of this privacy policy;
              </li>

              <li className="pb-3">
                To fulfill the purpose for which you provide it;
              </li>
              <li className="pb-3">
                If SpaceIt has a good faith belief that access, use,
                preservation or disclosure of your personal information is
                reasonably necessary to (a) satisfy any requirement of law,
                regulation, legal process, or enforceable governmental request,
                (b) enforce or investigate a potential violation of the Terms of
                Use, (c) detect, prevent, or otherwise respond to fraud,
                security or technical concerns, (d) support auditing and
                compliance functions, or (e) protect the rights, property, or
                safety of SpaceIt, its users, or the public against harm; and/or
              </li>
              <li className="pb-3">
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution or other
                sale or transfer of some or all of SpaceIt's assets, whether as
                a going concern or as part of bankruptcy, liquidation or similar
                proceeding, in which personal information about users of our
                Services is among the assets transferred.
              </li>
            </ul>
            <p>
              When you provide public information without identifying yourself,
              we will protect your personal identity as described above. If your
              account profile does not identify you personally, SpaceIt won't
              reveal your identity to third-parties except in accordance with
              this policy. You should keep in mind that the information that you
              choose to provide in a public area of our Services will not be
              protected by this privacy policy because you have made an active
              choice to make that information public. We are not responsible for
              any personal information you choose to submit in any public area.
            </p>
            <p>
              When we deliver ads based on data about your use of the Services,
              we will keep your identity anonymous. We won't identify you
              personally to third parties, except in accordance with this
              privacy policy. Of course, if you choose to respond to an
              advertised offer with your name and contact information, you will
              no longer be anonymous to the advertiser. Further, while we do not
              share your personal information with third parties unless
              permitted under the circumstances set forth above, SpaceIt may
              share aggregate anonymous information with third parties. This
              information does not personally identify individuals, but instead
              provides a helpful understanding of the groups of people who
              prefer certain types of information and services.
            </p>
            <p>
              SpaceIt has not sold, and will not sell, any personal information
              of yours or of any of its users, as those terms are defined under
              the California Consumer Privacy Act.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">Real Estate Information</h4>
            <p>
              SpaceIt strives to provide open and transparent information about
              the real estate market for which we provide Services. Many times,
              this information is already publicly available. As long as
              information provided to us or obtained by us concerns real estate
              (and not individuals), we intend to keep this information freely
              available through the Services.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">Email &amp; SMS Management</h4>
            <p>
              You may receive emails or text messages from SpaceIt for a variety
              of reasons- for example, if you took an action through our
              Services. We respect your desire to manage email correspondence
              and text messaging. If you have an account with SpaceIt, you can
              select your preferences through your account settings. Also, you
              can manage your receipt of some types of communication by
              following the instructions included in the email we send you.
              Please note that, even if you unsubscribe from certain email
              correspondences and text messaging, we may still need to email you
              with important administrative or other relevant information.{" "}
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">Behavioral Advertising</h4>
            <p>
              We may participate in behavioral advertising. This means that, for
              example, when you use the Services, we or third-party service
              providers or advertisers may use cookies (that they collect or
              that we provide to them) or other similar technologies to collect
              information about your use of the Services. Collected information
              may include the content you view, the date and time that you view
              this content, and the website that referred you to the Services,
              and this information may be associated with your unique browser,
              device identifier, or internet protocol address. These practices
              are intended to help tailor advertisements that are relevant and
              useful to you. These tailored advertisements may appear on the
              Services or on other websites, applications or properties.
            </p>
            <p>
              In addition, if you have provided your email address to us, we may
              use a scrambled, unreadable form (a hash) of your email address to
              deliver tailored advertisements to you on the Services or on other
              websites. You may opt-out of cookie tracking and analysis as
              described in the section below under the heading "Opting Out of
              Collection of Information by Third Parties." The Services do not
              otherwise respond to 'do not track' signals.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">
              Collection of Information by Third Parties
            </h4>
            <p>
              SpaceIt's third party ad servers, ad network providers, and
              third-party advertisers (the "SpaceIt Ad Providers") may provide
              you with advertisements that you may see on the Services or on
              other affiliated websites. To improve the relevancy and help
              measure the effectiveness of such advertisements, the SpaceIt Ad
              Providers may use cookies, web beacons, or similar technologies.
              These are used to record users' activity, such as the pages
              visited, and to learn what types of information are of most
              interest to the users. Use of these technologies by SpaceIt Ad
              Providers is subject to their own privacy policies and is not
              covered by our privacy policy.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <h4 className="f-18 fw-600">Location Data</h4>
            <p>
              SpaceIt can provide you with helpful information based on where
              you are. If you tell SpaceIt where you are (e.g., by allowing your
              device to send SpaceIt your location), SpaceIt may use that
              information to provide you with location-based information and
              advertising. If you wish to deactivate this feature, you can
              disable location services on your device.
            </p>

            <h4 className="f-18 fw-600">Third Party Websites</h4>
            <p>
              Throughout the Services, we may link to the websites of other
              companies and/or individuals. Further, certain functionalities on
              the Services may involve the distribution of your listing
              information to third party websites. These third-party websites
              may collect personal information about users on those websites,
              and SpaceIt's privacy policy does not extend to these external
              websites and third parties. Please refer directly to these
              third-party websites regarding their privacy policies.
            </p>
            <h4 className="f-18 fw-600">Children Under the Age of 13</h4>
            <p>
              SpaceIt will not knowingly collect information from any person
              under the age of 13. The Services are not designed to attract the
              attention of persons under the age of 13. If you are under 13, do
              not use or provide any information on the Services or on or
              through any of its features, register for the Services, make any
              purchases through the Services or provide any information about
              yourself to us, including your name, address, telephone number, or
              e-mail address.
            </p>

            <h4 className="f-18 fw-600">
              Storage, Security and Retention of Information
            </h4>
            <p>
              You acknowledge that personal information will be processed and
              stored by SpaceIt in databases hosted in the United States.
              SpaceIt will take reasonable steps to protect the information
              users share with us, including, but not limited to, setup of
              processes, equipment and software to avoid unauthorized access or
              disclosure of this information. However, no transmission of
              information via the internet can be entirely secure; therefore,
              please always use caution when submitting personal information.
            </p>
            <p>
              We will retain your personal information for as long as you have
              an active account, as needed to provide you with the Services, to
              comply with our legal, financial reporting, and compliance
              obligations, and to enforce this privacy policy.
            </p>

            <h4 className="f-18 fw-600">Non-Discrimination</h4>
            <p>
              SpaceIt will not discriminate against you for exercising your
              rights under the law, including without limitation, the California
              Consumer Privacy Act.
            </p>

            <h4 className="f-18 fw-600">Contacting SpaceIt</h4>
            <p>
              You can access, update, and delete personal information you
              provide to SpaceIt in your account profile by logging into your
              account or emailing us at{" "}
              <a href="mailto:support@spaceit.com">support@spaceit.com</a>.
              While we are ready to assist you in managing the personal
              information you provide to us, we cannot always delete records due
              to financial reporting, compliance obligations, or other reasons.
              Notwithstanding the foregoing, to the extent required by
              applicable law, you may have the right to request access to or
              delete your personal information. Specifically, you may have the
              right under the California Consumer Privacy Act to request
              information about the collection of your personal information, or
              access to or deletion of your personal information. If you wish to
              do any of these things, email us as at support@spaceit.com or log
              into your account. Depending on your data choices, certain
              services may be limited or unavailable. If you have any questions
              about this privacy policy, or the privacy practices of SpaceIt,
              please email us at{" "}
              <a href="mailto:support@spaceit.com">support@spaceit.com</a>.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default Privacy
